import { convertTokens, legacyParse } from '@date-fns/upgrade/v2'
import { format, isBefore, isSameMonth, isSameYear, subMonths } from 'date-fns'
import Dinero from 'dinero.js'
import {
  curry,
  descend,
  find,
  findIndex,
  flatten,
  groupBy,
  isNil,
  mapObjIndexed,
  move,
  remove,
  sort,
} from 'ramda'
import React from 'react'

import { Period, PeriodData } from './containers/SavingsContainer'
import { calculateSavingsRate, FiProjection } from './formulas'


export const isSSR = typeof window === 'undefined'

export function toCurrency(amount, currency, locale) {
  return Dinero({
    amount: isFinite(amount) ? amount : 0,
    precision: 2,
    currency,
  })
    .setLocale(locale)
    .toFormat('$0,0')
}

export const MONTHS = [
  [0, 'January'],
  [1, 'February'],
  [2, 'March'],
  [3, 'April'],
  [4, 'May'],
  [5, 'June'],
  [6, 'July'],
  [7, 'August'],
  [8, 'September'],
  [9, 'October'],
  [10, 'November'],
  [11, 'December'],
]

export const getValidPeriodMonths = (year: number, periods: Period[]) =>
  MONTHS.filter(([month]) => {
    if (
      find(period => period.year === year && period.month === month, periods)
    ) {
      return false
    }
    return true
  })

const groupPeriodsByType = (type: string, data: PeriodData[]) =>
  groupBy<PeriodData>(datum => datum.type, data)
const groupPeriodsByYear = groupBy<Period>(p => p.year.toString(10))
export const groupTotalsByYear = data =>
  mapObjIndexed((periods, yearKey) => {
    const year = parseInt(yearKey, 10)
    const yearEndPeriod = findYearEndPeriod(year, periods)

    if (yearEndPeriod) {
      return [yearEndPeriod]
    }

    // use all data for the year
    const newYearPeriod = {
      year,
      data: flatten(periods.map(p => p.data)),
    }

    return [newYearPeriod]
  }, groupPeriodsByYear(data))

export const findYearEndPeriod = curry((year, data) =>
  find<Period>(period => {
    return period.year === year && isNil(period.month)
  }, data)
)

export const findPeriodByYearAndMonth = curry((year, month, data) =>
  find<Period>(p => p.year === year && p.month === month, data)
)

export const findPeriodIndexByYearAndMonth = curry((year, month, data) =>
  findIndex<Period>(p => p.year === year && p.month === month, data)
)

export const findPeriodItemIndexByTypeAndName = curry((type, name, items) =>
  findIndex<PeriodData>(d => d.type === type && d.name === name, items)
)

export const sortPeriodsByDescending = sort(
  descend<Period>(period => new Date(period.year, period.month).getTime())
)

export const movePeriodItemDataUp = curry((type, name, items) => {
  const itemsByType = groupPeriodsByType(type, items)
  const foundItem = findPeriodItemIndexByTypeAndName(type, name, items)
  const indexByType = findPeriodItemIndexByTypeAndName(
    type,
    name,
    itemsByType[type]
  )

  if (indexByType > 0) {
    return move(foundItem, foundItem - 1, items)
  }
  return false
})

export const movePeriodItemDataDown = curry((type, name, items) => {
  const itemsByType = groupPeriodsByType(type, items)
  const foundItem = findPeriodItemIndexByTypeAndName(type, name, items)
  const indexByType = findPeriodItemIndexByTypeAndName(
    type,
    name,
    itemsByType[type]
  )

  if (indexByType >= 0 && indexByType < itemsByType[type].length - 1) {
    return move(foundItem, foundItem + 1, items)
  }
  return false
})

export const removePeriodItemData = curry((type, name, items) => {
  const foundItem = findPeriodItemIndexByTypeAndName(type, name, items)

  if (foundItem > -1) {
    return remove(foundItem, 1, items)
  }
  return false
})

export const getPeriodDisplayText = (year, month = undefined) => {
  if (month !== undefined) {
    return format(
      legacyParse(new Date(year, month, 1)),
      convertTokens('MMMM YYYY')
    )
  }
  if (year) {
    return `Total for ${year}`
  }
  return 'Unknown'
}

export const monthlyPeriodsToChartData = (periods: Period[] = []) => {
  if (periods.length === 0) {
    return []
  }

  // single YTD point
  if (periods.length === 1 && isNil(periods[0].month)) {
    const period = periods[0]
    return {
      [period.year]: (calculateSavingsRate(period.data) * 100).toFixed(0),
    }
  }

  // all points
  return periods
    .filter(period => !isNil(period.month))
    .reduce((acc, period) => {
      acc[new Date(period.year, period.month)] = (
        calculateSavingsRate(period.data) * 100
      ).toFixed(0)

      return acc
    }, {})
}

export const yearlyPeriodsToChartData = (periods = []) => {
  if (periods.length === 0) {
    return []
  }

  // all points
  return periods
    .filter(period => isNil(period.month))
    .reduce((acc, period) => {
      acc[period.year] = (calculateSavingsRate(period.data) * 100).toFixed(0)

      return acc
    }, {})
}

export function stashProjectionToChartData(projection: FiProjection[] = []) {
  if (projection.length === 0) {
    return []
  }

  const now = new Date()
  return projection.reduce((acc, estimate) => {
    const year = now.getFullYear() + estimate.t

    acc[`${year}-${now.getMonth() + 1}`] = estimate.total

    return acc
  }, {})
}

export function getPreviousPeriod(currentPeriod, periods) {
  const sortedPeriods = sortPeriodsByDescending(periods)

  if (!isNil(currentPeriod.year) && isNil(currentPeriod.month)) {
    // find previous total year
    return find(
      period => period.year < currentPeriod.year && isNil(period.month),
      sortedPeriods
    )
  } else if (!isNil(currentPeriod.year) && !isNil(currentPeriod.month)) {
    // find previous month and year
    const previousTimePeriod = subMonths(
      legacyParse(new Date(currentPeriod.year, currentPeriod.month)),
      1
    )
    return find(period => {
      const periodDate = new Date(period.year, period.month)

      // matches if before, or if same month/same year
      return (
        isBefore(legacyParse(periodDate), legacyParse(previousTimePeriod)) ||
        (isSameMonth(
          legacyParse(periodDate),
          legacyParse(previousTimePeriod)
        ) &&
          isSameYear(legacyParse(periodDate), legacyParse(previousTimePeriod)))
      )
    }, sortedPeriods)
  }

  return undefined
}

export function formatPercentage(perc) {
  return (perc * 100).toFixed(0) + '%'
}

export function toAmount(decimalAmount) {
  return parseInt((parseFloat(decimalAmount) * 100).toFixed(0), 10)
}

export function fromAmount(amount) {
  return amount / 100
}

export const withProps = (WrappedComponent, props) =>
  class extends React.Component {
    render() {
      return <WrappedComponent {...this.props} {...props} />
    }
  }

export const parseQueryString = function(str) {
  var ret = Object.create(null)

  if (typeof str !== 'string') {
    return ret
  }

  str = str.trim().replace(/^(\?|#|&)/, '')

  if (!str) {
    return ret
  }

  str.split('&').forEach(function(param) {
    var parts = param.replace(/\+/g, ' ').split('=')
    // Firefox (pre 40) decodes `%3D` to `=`
    // https://github.com/sindresorhus/query-string/pull/37
    var key = parts.shift()
    var val = parts.length > 0 ? parts.join('=') : undefined

    key = decodeURIComponent(key)

    // missing `=` should be `null`:
    // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
    val = val === undefined ? null : decodeURIComponent(val)

    if (ret[key] === undefined) {
      ret[key] = val
    } else if (Array.isArray(ret[key])) {
      ret[key].push(val)
    } else {
      ret[key] = [ret[key], val]
    }
  })

  return ret
}
