import localforage from 'localforage'
import { all, pipe } from 'ramda'
import { Container } from 'unstated'

import { isSSR } from '../util'

export const isBootstrapped = (...containers) =>
  all(c => c.bootstrapped === true, containers)

type VersionedState<S extends object> = S & { version: number }

export default function withLocalStorage<TData extends object>(
  DataContainer: new <S extends object>() => Container<TData>,
  { name, version = 1 }
): new () => Container<TData> {
  return class extends DataContainer<TData> {
    bootstrapped: boolean
    state: VersionedState<TData>

    constructor() {
      super()

      this.bootstrapped = false

      if (!isSSR) {
        localforage
          .getItem<TData>(name)
          .then(value => {
            this.bootstrapped = true

            super.setState(value || this.state, () => {
              console.log('[info] Loaded state', name, value)
            })
          })
          .catch(err => {
            throw err
          })
      } else {
        this.bootstrapped = true
      }
    }

    setState = (
      state: TData | ((prevState: TData) => TData),
      callback = () => {}
    ) => {
      let stateValue = state

      if (typeof state === 'function') {
        stateValue = pipe(
          state as any,
          (state: TData) => ({ ...state, version })
        )
      } else if (typeof state === 'object') {
        stateValue = { ...state, version }
      }

      return super.setState(stateValue, async () => {
        await this._persist()
        if (callback) {
          callback()
        }
      })
    }

    _persist = async () => {
      console.log('[info] Persisted state', name, this.state)
      await localforage.setItem(name, this.state)
    }
  }
}
