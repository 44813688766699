import { Container } from 'unstated'

import { isSSR } from '../util'
import withLocalStorage from './withLocalStorage'

class SettingsContainer extends Container {
  state = {
    currency: 'USD',
    onboarded: false,
    locale:
      (!isSSR ? navigator.language : false) ||
      'en-US',
    
  }

  setOnboardedSuccess = () => {
    this.setState({ onboarded: true })
  }

  setCurrency = currency => {
    this.setState({ currency })
  }

  setLocale = locale => {
    this.setState({ locale })
  }
}

export default withLocalStorage(SettingsContainer, {
  name: 'settings',
  version: 2,
})
