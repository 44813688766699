import amber from '@material-ui/core/colors/amber'
import green from '@material-ui/core/colors/green'
import grey from '@material-ui/core/colors/grey'
import red from '@material-ui/core/colors/red'

export const APP_INSIGHTS_KEY = '565392a0-a18e-4668-a323-b4bc6451ac38'

export const DROPBOX_COLOR = '#005ff8'

export enum RateFormula {
  GROSS = 1,
  TAKE_HOME = 2,
  AFTER_TAX = 3,
  AFTER_TAX_DEFERRED = 4,
}

export const TAGS = {
  PRE_TAX: 'Pre-tax',
  RETIREMENT_401k: '401k',
  RETIREMENT_403b: '403b',
  RETIREMENT_457b: '457b',
  RETIREMENT_SEP: 'SEP IRA',
  RETIREMENT_SOLO: 'Solo 401k',
  RETIREMENT_SIMPLE: 'SIMPLE IRA',
  AFTER_TAX: 'After-tax',
  BROKERAGE: 'Brokerage',
  RETIREMENT_ROTH: 'Roth',
  RETIREMENT_TRAD: 'Traditional',
  FSA: 'FSA',
  HSA: 'HSA',
  IRA: 'IRA',
  MORTGAGE_PRINCIPAL: 'Mortgage Principal',
  EXPENSE: 'Expense',
  DEDUCTION: 'Deduction',
  TAX: 'Tax',
  DEPENDENT_CARE: 'Daycare FSA',
}

export const SAVINGS_COMPONENTS = {
  S1: {
    order: 1,
    color: green[500],
    heading: 'Employer Matching (S1)',
    description:
      'Amounts that your employer contributes to your savings such as 401k, 403b, or HSA matches',
    defaultLineItems: [
      {
        name: '401k Matching',
        tags: [TAGS.PRE_TAX, TAGS.RETIREMENT_401k],
      },
    ],
  },
  S2: {
    order: 2,
    color: green[500],
    heading: 'Voluntary Deferrals (S2)',
    description:
      'Tax-deferred savings that you contribute to such as 401k, 403b, HSA, or other tax-deferred account',
    defaultLineItems: [
      {
        name: '401k Contributions',
        tags: [TAGS.PRE_TAX, TAGS.RETIREMENT_401k],
      },
    ],
  },
  S3: {
    order: 4,
    color: green[500],
    heading: 'Other Savings (S3)',
    description:
      'Additional after-tax savings such as mortgage principal paydown, IRA contributions, or taxable investments',
    defaultLineItems: [
      {
        name: 'Roth IRA Contributions',
        tags: [TAGS.AFTER_TAX, TAGS.IRA, TAGS.RETIREMENT_ROTH],
      },
      {
        name: 'Post-Tax (Taxable) Savings',
        tags: [TAGS.AFTER_TAX, TAGS.BROKERAGE],
      },
    ],
  },
  C: {
    order: 6,
    color: amber[500],
    heading: 'Consumption (C)',
    description: 'Spending and other expenses',
    defaultLineItems: [{ name: 'Expenses', tags: [TAGS.EXPENSE] }],
  },
  D: {
    order: 5,
    color: grey[500],
    heading: 'Deductions (D)',
    description: 'Deductions like employer healthcare, commuter benefits, etc.',
    defaultLineItems: [
      { name: 'Pre-Tax Health Premium', tags: [TAGS.DEDUCTION] },
    ],
  },
  T: {
    order: 3,
    color: red[500],
    heading: 'Taxes (T)',
    description: 'Payroll, federal, state, local income taxes',
    defaultLineItems: [
      { name: 'Payroll Tax', tags: [TAGS.TAX] },
      { name: 'Federal Income Tax', tags: [TAGS.TAX] },
      { name: 'State Income Tax', tags: [TAGS.TAX] },
    ],
  },
}

export const WELCOME_ACCOUNTS = [
  {
    lineItems: [
      {
        category: 'S1',
        name: '401k Matching',
        tags: [TAGS.PRE_TAX, TAGS.RETIREMENT_401k],
      },
      {
        category: 'S2',
        name: '401k Contributions',
        tags: [TAGS.PRE_TAX, TAGS.RETIREMENT_401k],
      },
    ],
    name: 'Traditional 401k',
    description:
      'Common tax-deductible retirement account for for-profit companies',
  },
  {
    lineItems: [
      {
        category: 'S1',
        name: '403b Matching',
        tags: [TAGS.PRE_TAX, TAGS.RETIREMENT_403b],
      },
      {
        category: 'S2',
        name: '403b Contributions',
        tags: [TAGS.PRE_TAX, TAGS.RETIREMENT_403b],
      },
    ],
    name: '403b',
    description:
      'Common tax-deductible retirement account for non-profit companies, same as a 401k',
  },
  {
    lineItems: [
      {
        category: 'S1',
        name: '457b Matching',
        tags: [TAGS.PRE_TAX, TAGS.RETIREMENT_457b],
      },
      {
        category: 'S2',
        name: '457b Contributions',
        tags: [TAGS.PRE_TAX, TAGS.RETIREMENT_457b],
      },
    ],
    name: '457b',
    description:
      'Common tax-deductible retirement account for governmental employees',
  },

  {
    lineItems: [
      { category: 'S1', name: 'HSA Matching', tags: [TAGS.PRE_TAX, TAGS.HSA] },
      {
        category: 'S2',
        name: 'HSA Contributions',
        tags: [TAGS.PRE_TAX, TAGS.HSA],
      },
    ],
    name: 'Health Savings Account (HSA)',
    description:
      'A tax deductible account for paying qualified medical expenses.',
  },
  {
    lineItems: [
      {
        category: 'S3',
        name: 'Post-Tax (Taxable) Savings',
        tags: [TAGS.AFTER_TAX, TAGS.BROKERAGE],
      },
    ],
    name: 'Brokerage (Investment) Account',
    description:
      'An account used to invest in stocks, bonds, money market accounts, etc.',
  },

  {
    lineItems: [
      {
        category: 'S2',
        name: 'Traditional IRA Contributions',
        tags: [TAGS.PRE_TAX, TAGS.IRA, TAGS.RETIREMENT_TRAD],
      },
    ],
    name: 'Traditional IRA',
    description:
      'An IRA where you can deduct contributions now and pay taxes later when you withdraw funds.',
  },
  {
    lineItems: [
      {
        category: 'S3',
        name: 'Roth IRA Contributions',
        tags: [TAGS.AFTER_TAX, TAGS.IRA, TAGS.RETIREMENT_ROTH],
      },
    ],
    name: 'Roth IRA',
    description:
      'An IRA where you pay taxes now but can withdraw tax free, subject to certain conditions.',
  },

  {
    lineItems: [
      {
        category: 'S3',
        name: 'Mortgage Principal Payment',
        tags: [TAGS.MORTGAGE_PRINCIPAL],
      },
    ],
    name: 'Mortgage Loan',
    description:
      'Typical for homeowners and rental properties, this is the loan you pay off.',
  },
  {
    lineItems: [
      {
        category: 'S2',
        name: 'Daycare FSA',
        tags: [TAGS.PRE_TAX, TAGS.FSA, TAGS.DEPENDENT_CARE],
      },
    ],
    name: 'Daycare Spending Account (DCSA)',
    description:
      'A tax deductible "flexible spending account" that can be used to pay for qualified dependent care expenses like daycare or after-school care.',
  },
  {
    lineItems: [
      {
        category: 'S1',
        name: 'Roth 401k Matching',
        tags: [TAGS.AFTER_TAX, TAGS.RETIREMENT_401k, TAGS.RETIREMENT_ROTH],
      },
      {
        category: 'S3',
        name: 'Roth 401k Contributions',
        tags: [TAGS.AFTER_TAX, TAGS.RETIREMENT_401k, TAGS.RETIREMENT_ROTH],
      },
    ],
    name: 'Roth 401k',
    description: 'Common after-tax retirement account for for-profit companies',
  },
  {
    lineItems: [
      {
        category: 'S1',
        name: 'SEP IRA Matching',
        tags: [TAGS.PRE_TAX, TAGS.RETIREMENT_SEP, TAGS.IRA],
      },
      {
        category: 'S2',
        name: 'SEP IRA Contributions',
        tags: [TAGS.PRE_TAX, TAGS.RETIREMENT_SEP, TAGS.IRA],
      },
    ],
    name: 'SEP IRA',
    description:
      'A type of traditional IRA for self-employed individuals or businesses.',
  },
  {
    lineItems: [
      {
        category: 'S1',
        name: 'Solo 401k Matching',
        tags: [TAGS.PRE_TAX, TAGS.RETIREMENT_SOLO],
      },
      {
        category: 'S2',
        name: 'Solo 401k Contributions',
        tags: [TAGS.PRE_TAX, TAGS.RETIREMENT_SOLO],
      },
    ],
    name: 'Solo 401k',
    description:
      'A type of traditional 401k for businesses with no full-time employees other than business owner(s) or their spouse(s).',
  },
  {
    lineItems: [
      {
        category: 'S1',
        name: 'SIMPLE IRA Matching',
        tags: [TAGS.PRE_TAX, TAGS.RETIREMENT_SIMPLE, TAGS.IRA],
      },
      {
        category: 'S2',
        name: 'SIMPLE IRA Contributions',
        tags: [TAGS.PRE_TAX, TAGS.RETIREMENT_SIMPLE, TAGS.IRA],
      },
    ],
    name: 'SIMPLE IRA',
    description:
      'A type of traditional IRA for self-employed individuals or small businesses with 100 or fewer employees.',
  },
]
