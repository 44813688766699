import { css, keyframes } from '@emotion/core'
import IconSync from '@material-ui/icons/Sync'
import DropboxIcon from 'mdi-material-ui/Dropbox'
import React from 'react'

import { DROPBOX_COLOR } from '../constants'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const SyncIcon = (color = DROPBOX_COLOR) => (
  <IconSync
    css={css`
      color: ${color};
      animation: ${spin} 2s infinite;
    `}
  />
)

export default props => <DropboxIcon {...props} css={{ color: DROPBOX_COLOR }} />
