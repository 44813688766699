import { findPeriodIndexByYearAndMonth } from '../util'
import withLocalStorage from './withLocalStorage'
import localforage from 'localforage'
import { remove, update } from 'ramda'
import { Container } from 'unstated'

import { RateFormula, SAVINGS_COMPONENTS } from '../constants'

export interface Period {
  year: number
  month?: number
  data?: PeriodData[]
}

export interface PeriodData {
  category?: string;
  tags?: string[];
  type?: string;
  name: string;
  value: number;
}

export interface SavingsState {
  periods: Period[]
  targetRate: number
  rateFormula: RateFormula
  hideExactSavings: boolean
  defaultComponents: object
}

class SavingsContainer extends Container<SavingsState> {
  state: SavingsState = {
    periods: [],

    // Target goal rate to meet
    targetRate: 0.55,
    rateFormula: RateFormula.AFTER_TAX,
    hideExactSavings: false,
    defaultComponents: {
      S1: SAVINGS_COMPONENTS.S1.defaultLineItems,
      S2: SAVINGS_COMPONENTS.S2.defaultLineItems,
      S3: SAVINGS_COMPONENTS.S3.defaultLineItems,
      T: SAVINGS_COMPONENTS.T.defaultLineItems,
      D: SAVINGS_COMPONENTS.D.defaultLineItems,
      C: SAVINGS_COMPONENTS.C.defaultLineItems,
    },
  }

  constructor() {
    super()

    // Migrate from periods data store
    localforage.getItem('periods', (err, value: SavingsState) => {
      if (value) {
        this.setState(
          prevState => ({ ...prevState, ...value }),
          () => {
            localforage.removeItem('periods')
          }
        )
      }
    })
  }

  setRateFormula = (rateFormula: RateFormula) => {
    this.setState({ rateFormula })
  }

  setDefaultComponents = components => {
    this.setState({ defaultComponents: components })
  }

  setTargetRate = (targetRate: number) => {
    this.setState({ targetRate })
  }

  setPeriod = (
    year: number,
    month: number = undefined,
    period: Period,
    callback = null
  ) => {
    const existingPeriod = findPeriodIndexByYearAndMonth(
      year,
      month,
      this.state.periods
    )

    if (existingPeriod > -1) {
      this.setState(
        state => ({
          periods: update(existingPeriod, period, state.periods),
        }),
        callback
      )
    } else {
      this.setState(
        state => ({
          periods: [...state.periods, period],
        }),
        callback
      )
    }
  }

  removePeriod = (year: number, month: number = undefined, callback = null) => {
    const existingPeriod = findPeriodIndexByYearAndMonth(
      year,
      month,
      this.state.periods
    )

    if (existingPeriod > -1) {
      return this.setState(
        state => ({
          periods: remove(existingPeriod, 1, state.periods),
        }),
        callback
      )
    }
  }

  setHideExactSavings = (hide: boolean) => {
    this.setState({ hideExactSavings: hide })
  }
}

export default withLocalStorage(SavingsContainer, {
  name: 'savings',
  version: 1,
})
