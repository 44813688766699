import { RateFormula } from './constants'
import { PeriodData } from './containers/SavingsContainer'
import * as R from 'ramda'

const sumPeriodValue = (acc: number, { value }: PeriodData) => acc + value
const toType = ({ type }: PeriodData) => type
const sumDataComponents = R.reduceBy(sumPeriodValue, 0, toType)

/**
 * Scenario 1: Based on gross total compensation
 * (S1 + S2 + S3) / (S1 + S2 + S3 + T + D + C)
 * @param {PeriodData[]} period The period's data including type and value
 */
function calculateGross(data: PeriodData[]) {
  const { S1 = 0, S2 = 0, S3 = 0, T = 0, D = 0, C = 0 } = sumDataComponents(
    data
  )
  const savings = S1 + S2 + S3
  const rate = savings / (savings + T + D + C)

  return { savings, rate: isNaN(rate) ? 0 : rate }
}

/**
 * Scenario 2: Based on take-home pay
 * (S1 + S2 + S3) / (S3 + C)
 * @param {PeriodData[]} period The period's data including type and value
 */
function calculateTakeHome(data: PeriodData[]) {
  const { S1 = 0, S2 = 0, S3 = 0, T = 0, D = 0, C = 0 } = sumDataComponents(
    data
  )
  const savings = S1 + S2 + S3
  const rate = savings / (S3 + C)

  return { savings, rate: isNaN(rate) ? 0 : rate }
}

/**
 * Scenario 3: Based on after-tax savings
 * (S1 + S2 + S3) / (S1 + S2 + S3 + D + C)
 * @param {PeriodData[]} period The period's data including type and value
 */
function calculateAfterTax(data: PeriodData[]) {
  const { S1 = 0, S2 = 0, S3 = 0, T = 0, D = 0, C = 0 } = sumDataComponents(
    data
  )
  const savings = S1 + S2 + S3
  const rate = savings / (savings + D + C)

  return { savings, rate: isNaN(rate) ? 0 : rate }
}

/**
 * Scenario 4: Based on after-tax savings, adjusting for deferred taxes
 * T1 = (S1 * r1) + (S2 * r2)
 * (S1 + S2 + S3) / (S1 + S2 + S3 + T1 + D + C)
 * @param {PeriodData[]} period The period's data including type and value
 */
function calculateAfterTaxDeferred(
  data: PeriodData[],
  retiredMarginalRate = 0.15,
  currentMarginalRate = 0.25
) {
  const { S1 = 0, S2 = 0, S3 = 0, T = 0, D = 0, C = 0 } = sumDataComponents(
    data
  )
  const deferredTaxes = S1 * retiredMarginalRate + S2 * currentMarginalRate
  const savings = S1 + S2 + S3

  const rate = savings / (savings + deferredTaxes + D + C)

  return { savings, rate: isNaN(rate) ? 0 : rate }
}

function getSavings(savingsData: PeriodData[], formula: RateFormula) {
  switch (formula) {
    case RateFormula.GROSS: {
      return calculateGross(savingsData)
    }
    case RateFormula.TAKE_HOME: {
      return calculateTakeHome(savingsData)
    }
    case RateFormula.AFTER_TAX: {
      return calculateAfterTax(savingsData)
    }
    case RateFormula.AFTER_TAX_DEFERRED: {
      return calculateAfterTaxDeferred(savingsData)
    }
    default:
      throw new Error('No savings formula defined for ' + formula)
  }
}

export function calculateSavings(
  savingsData: PeriodData[] = [],
  formula = RateFormula.AFTER_TAX
) {
  return getSavings(savingsData, formula).savings
}

export function calculateConsumption(savingsData: PeriodData[] = []) {
  const { C = 0 } = sumDataComponents(savingsData)

  return C
}

/**
 * Calculate the savings rate for a period
 * using the provided savings rate formula
 */
export function calculateSavingsRate(
  savingsData: PeriodData[] = [],
  formula = RateFormula.AFTER_TAX
) {
  return getSavings(savingsData, formula).rate
}

/**
 * Calculate the average savings rate over a set of periods
 * using the provided savings rate formula
 */
export function calculateAverageSavingsRate(
  periods: PeriodData[][] = [],
  formula = RateFormula.AFTER_TAX
) {
  return (
    periods.reduce((acc, data) => {
      const rate = getSavings(data, formula).rate

      return acc + rate
    }, 0) / periods.length
  )
}

const filterByS1 = R.filter<PeriodData>(x => x.type === 'S1')
const filterByS2 = R.filter<PeriodData>(x => x.type === 'S2')
const filterByS3 = R.filter<PeriodData>(x => x.type === 'S3')
const filterByC = R.filter<PeriodData>(x => x.type === 'C')
const filterByT = R.filter<PeriodData>(x => x.type === 'T')
const filterByD = R.filter<PeriodData>(x => x.type === 'D')

export function getFormulaComponents(savingsData: PeriodData[] = []) {
  return {
    S1: filterByS1(savingsData),
    S2: filterByS2(savingsData),
    S3: filterByS3(savingsData),
    C: filterByC(savingsData),
    T: filterByT(savingsData),
    D: filterByD(savingsData),
  }
}

export interface FiProjection {
  t: number
  covered: number
  gain: number
  total: number
}

export function calculateProjectedStash(
  initialAmount: number,
  expectedExpenses: number,
  expectedSavingsRate: number,
  expectedRateOfReturn: number,
  withdrawalRate: number
) {
  const initialPeriod: FiProjection = {
    t: 0,
    covered: 0,
    gain: 0,
    total: initialAmount,
  }

  const totalPeriods = 100 // next 100 years
  const projection = [initialPeriod]
  const expectedIncome = expectedExpenses / (1 - expectedSavingsRate)
  const periodSavings = expectedIncome - expectedExpenses
  let last = initialPeriod

  for (let t = 1; t <= totalPeriods; t++) {
    const gain = Math.round(
      (last.total + periodSavings / 2) * expectedRateOfReturn
    )
    const next = {
      t,
      gain,
      covered: gain / expectedExpenses,
      total: Math.round(last.total + periodSavings + gain),
    }
    projection.push(next)
    last = next

    if (next.covered > 1.2) {
      break
    }
  }

  return projection
}

export function calculateFiEstimate(
  initialAmount: number,
  expectedExpenses: number,
  expectedSavingsRate: number,
  expectedRateOfReturn: number,
  withdrawalRate: number
) {
  const projection = calculateProjectedStash(
    initialAmount,
    expectedExpenses,
    expectedSavingsRate,
    expectedRateOfReturn,
    withdrawalRate
  )

  const found = R.find<FiProjection>(p => p.covered >= 1.2)(projection)

  if (found) {
    return found
  }
  return null
}
